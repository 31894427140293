@import "../../../../styles/mixins.scss";

.skeleton-loader {
  width: 100%;
  max-width: 2400px;
  margin: 0 auto;
  //padding: 20px;
  @include mobile {
    padding: 0 0;
  }

  &__title {
    height: 40px;
    width: 200px;
    margin-bottom: 20px;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    @include mobile {
    }
  }

  &__card {
    // flex: 1 1 200px;
    // max-width: 300px;
    flex: 1 1 calc(20% - 16px);
    max-width: calc(20% - 16px);
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    background: #fff;
    @include for-size(1000px) {
      flex: 1 1 calc(33.333% - 13.333px);
      max-width: calc(33.333% - 13.333px);
    }
    @include for-size(767px) {
      flex: 1 1 calc(33.333% - 13.333px);
      max-width: calc(33.333% - 13.333px);
    }
    @include for-size(500px) {
      flex: 1 1 calc(50% - 10px);
      max-width: calc(50% - 10px);
    }

    &--hide-mobile {
      @include for-size(500px) {
        display: none;
      }
    }
    &--hide-tablet {
      @include for-size(1000px) {
        display: none;
      }
    }
  }

  &__image {
    height: 150px;
    margin-bottom: 16px;
    @include mobile {
      height: 80px;
    }
  }

  &__content {
    flex-grow: 1;
  }

  &__text {
    height: 20px;
    margin-bottom: 8px;

    &:last-child {
      width: 80%;
    }
  }

  &__button {
    height: 32px;
    margin-top: 42px;
  }

  &__animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    // background: linear-gradient(to right, #f6f7f8 8%, #edeef1 18%, #f6f7f8 33%);
    background-color: #e2e5e7;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0)
    );
    background-size: 800px 104px;
    height: 100%;
    position: relative;
    border-radius: 4px;
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
